<template>
  <index-services />
</template>

<script>
import IndexServices from '@/components/Services/Index';

export default {
  name: "Services",
  components: {
    IndexServices,
  }
}
</script>

<style scoped>

</style>