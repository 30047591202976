<template>
  <div>
    <services></services>
    <testimonials></testimonials>
  </div>
</template>

<script>
import Services from './Services';
import Testimonials from './Testimonials';

export default {
  name: 'Index',
  components: {
    Services,
    Testimonials,
  }
}
</script>

<style scoped>

</style>